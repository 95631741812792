import { template as template_8bbd0ff7b75a471a9fc0e3baade7919e } from "@ember/template-compiler";
const FKLabel = template_8bbd0ff7b75a471a9fc0e3baade7919e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
