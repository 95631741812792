import { template as template_5ba54288777e4adabc6ed94b08b14b05 } from "@ember/template-compiler";
const WelcomeHeader = template_5ba54288777e4adabc6ed94b08b14b05(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
