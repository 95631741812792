import { template as template_0ee1689a15aa4e0e9d454dfebf1cae80 } from "@ember/template-compiler";
const FKText = template_0ee1689a15aa4e0e9d454dfebf1cae80(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
