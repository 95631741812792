import { template as template_7d9887cb1ddd4715b9e2875840093c61 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7d9887cb1ddd4715b9e2875840093c61(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
